import React from "react";
import { FontAwesomeIcon as FA } from "@fortawesome/react-fontawesome";

export default function Loading() {
  return (
    <div className="w-full h-full flex justify-center items-center">
      <div>
        <FA fixedWidth={true} icon="fa-arrows-spin" size="2xl" spin />
        <span className="ml-4 font-bold text-xl">Loading ...</span>
      </div>
    </div>
  );
}
