import React, { useMemo, useEffect, useCallback, useState } from "react";
import {
  useForm,
  FormProvider,
  Controller,
  useFormContext,
  useController,
} from "react-hook-form";
import { format as formatValue } from "@buttercup/react-formatted-input";
import {
  Combobox,
  ComboboxButton,
  ComboboxInput,
  ComboboxOption,
  ComboboxOptions,
} from "@headlessui/react";
import { FontAwesomeIcon as FA } from "@fortawesome/react-fontawesome";

export function Form({ defaultValues, children, onSubmit, ...rest }) {
  const methods = useForm({ defaultValues });
  const { reset } = methods;
  useEffect(() => {
    reset(defaultValues ?? {});
  }, [defaultValues, reset]);

  function onSubmitWrapped(data, e) {
    return onSubmit(data, e, methods);
  }

  const submitCall = methods.handleSubmit(onSubmitWrapped);

  return (
    <FormProvider {...methods} submitCall={submitCall}>
      <form onSubmit={submitCall} {...rest}>
        {children}
      </form>
    </FormProvider>
  );
}

export default Form;

export function FormRow({ children }) {
  return (
    <div className="flex flex-wrap -mx-3 mb-6">
      <div className="w-full px-3">{children}</div>
    </div>
  );
}

export function Label({ children }) {
  return (
    <label className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2">
      {children}
    </label>
  );
}

export function Error({ forName, children }) {
  const {
    formState: { errors },
  } = useFormContext();
  const error = errors && errors[forName];
  if (!error?.type && !children) return null;

  return (
    <p className="text-red-500 text-xs italic font-normal tracking-normal normal-case">
      {error?.message ? error.message : children}
    </p>
  );
}

export function Input({ name, validate, ...rest }) {
  const {
    register: registerMethod,
    formState: { dirty, errors },
  } = useFormContext();

  const register = registerMethod(name, validate);
  const error = errors && errors[name]?.type !== undefined;

  return useMemo(
    () => {
      const visual =
        rest?.type === `hidden`
          ? {}
          : {
              className:
                `block w-full mt-1 mb-3 ` +
                (error ? `border-red-500 focus:border-red-500` : ``),
            };

      return <input {...visual} type="text" {...register} {...rest} />;
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [dirty, name, error, rest, register]
  );
}

export function TextArea({ name, validate, ...rest }) {
  const {
    register: registerMethod,
    formState: { dirty, errors },
  } = useFormContext();

  const register = registerMethod(name, validate);
  const error = errors && errors[name]?.type !== undefined;

  return useMemo(
    () => {
      const visual =
        rest?.type === `hidden`
          ? {}
          : {
              className:
                `block w-full mt-1 mb-3 ` +
                (error ? `border-red-500 focus:border-red-500` : ``),
            };

      return <textarea {...visual} {...register} {...rest} />;
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [dirty, name, error, rest, register]
  );
}

export function FormattedInput({ name, validate, format, ...rest }) {
  const {
    formState: { dirty, errors },
  } = useFormContext();

  const error = errors && errors[name]?.type !== undefined;

  const updateValue = useCallback(
    (e) => formatValue(e.target.value, format)?.formatted,
    [format]
  );

  return useMemo(
    () => {
      const visual =
        rest?.type === `hidden`
          ? {}
          : {
              className:
                `block w-full mt-1 mb-3 ` +
                (error ? `border-red-500 focus:border-red-500` : ``),
            };

      return (
        <Controller
          name={name}
          render={({ field }) => (
            <input
              {...visual}
              {...field}
              onChange={(e) => field.onChange(updateValue(e))}
              {...rest}
            />
          )}
          rules={validate}
          type="text"
        />
      );
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [dirty, name, error, rest, format, validate]
  );
}

export function Button({ name, children, className, ...rest }) {
  const { register, setValue } = useFormContext() ?? {};
  return (
    <button
      className={`bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:ring ${
        className ?? ``
      }`.trim()}
      {...(name ? register(name) : {})}
      onClick={() => name && setValue(name, rest?.value)}
      {...rest}
    >
      {children}
    </button>
  );
}

export function Submit({ name, ...rest }) {
  return <Button name={name} type="submit" {...rest} />;
}

function buildOptionEnries(options, watch) {
  if (options && Array.isArray(options)) {
    return options.map((value) =>
      value && Array.isArray(value) ? value : [value, value]
    );
  } else {
    return Object.entries(options);
  }
}

export function Select({ options, name, validate, ...rest }) {
  const {
    register: registerMethod,
    formState: { dirty, errors },
    watch,
  } = useFormContext();
  const register = registerMethod(name, validate);
  const error = errors && errors[name]?.type !== undefined;

  return useMemo(
    () => {
      const optionEntries = buildOptionEnries(options, watch);
      const visual = {
        className:
          `block w-full mt-1 mb-3 ` +
          (error ? `border-red-500 focus:border-red-500` : ``),
      };
      return (
        <select {...visual} {...register} {...rest}>
          {optionEntries.map(([value, label]) => (
            <option key={value} value={value}>
              {label}
            </option>
          ))}
        </select>
      );
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [dirty, name, rest, register]
  );
}

export function ComboBox({ options, name, validate, ...rest }) {
  const {
    register: registerMethod,
    formState: { errors },
    watch,
    control,
  } = useFormContext();
  const { field } = useController({ name, control });
  const [query, setQuery] = useState(``);
  registerMethod(name, validate);
  const error = errors && errors[name]?.type !== undefined;

  const optionEntries = buildOptionEnries(options, watch);
  const visual = {
    className:
      `block w-full mt-1 mb-3 ` +
      (error ? `border-red-500 focus:border-red-500` : ``),
  };
  return (
    <Combobox onChange={field.onChange} value={field.value}>
      <div className="relative">
        <ComboboxInput
          {...visual}
          name={field.name}
          onBlur={field.onBlur}
          ref={field.ref}
          {...rest}
          displayValue={(selected) => selected}
          onChange={(event) => setQuery(event.target.value)}
        />
        <ComboboxButton className="group absolute inset-y-0 right-0 px-2.5">
          <FA
            className="size-4 fill-white/60 group-data-[hover]:fill-white"
            icon="caret-down"
          />
        </ComboboxButton>
      </div>
      <ComboboxOptions
        anchor="bottom"
        className="w-[var(--input-width)] rounded-xl border border-white bg-white p-1 [--anchor-gap:var(--spacing-1)] empty:invisible"
      >
        {query.length > 0 && (
          <ComboboxOption
            className="cursor-default items-center gap-2 rounded-lg py-1.5 px-3 select-none data-[focus]:bg-gray-200"
            value={query}
          >
            {query}
          </ComboboxOption>
        )}
        {optionEntries
          .filter(([value, label]) =>
            label.toLowerCase().includes(query.toLowerCase())
          )
          .map(([value, label]) => (
            <ComboboxOption
              className="cursor-default items-center gap-2 rounded-lg py-1.5 px-3 select-none data-[focus]:bg-gray-200"
              key={value}
              value={value}
            >
              {label}
            </ComboboxOption>
          ))}
      </ComboboxOptions>
    </Combobox>
  );
}

export function Radio({ name, validate, children, ...rest }) {
  const {
    register: registerMethod,
    formState: { dirty },
  } = useFormContext();
  const register = registerMethod(name, validate);

  return useMemo(
    () => {
      return (
        <label className="font-normal text-base tracking-normal normal-case flex items-center mt-1 mb-3">
          <input
            className="mr-2 h-6 w-6"
            {...register}
            type="radio"
            {...rest}
          />
          <span>{children}</span>
        </label>
      );
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [dirty, name, rest, register]
  );
}
