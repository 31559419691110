import React from "react";
import { FontAwesomeIcon as FA } from "@fortawesome/react-fontawesome";

// TODO: add status conditions (in running period, after start)
const eventsDef = {
  // zahajovaci utok/obrana + cisla dresu, nahradnici, kapitan
  starting_players: {
    label: `Zahajovací sestava`,
    phase: [`start`],
    button: { icon: `user-friends` },
    message: (event, def, getTeamTitle, getPersonTitle) => (
      <>
        <strong>
          {def.label} {getTeamTitle(`home`)}:
        </strong>
        <div className="flex flex-wrap">
          <StartingPlayersRole
            className="mr-3"
            role="attack"
            team={event.home}
            {...{ getPersonTitle }}
          />
          <StartingPlayersRole
            role="defense"
            team={event.home}
            {...{ getPersonTitle }}
          />
        </div>
        <br />
        <strong>
          {def.label} {getTeamTitle(`guest`)}:
        </strong>
        <div className="flex flex-wrap">
          <StartingPlayersRole
            className="mr-3"
            role="attack"
            team={event.guest}
            {...{ getPersonTitle }}
          />
          <StartingPlayersRole
            role="defense"
            team={event.guest}
            {...{ getPersonTitle }}
          />
        </div>
      </>
    ),
  },

  score: {
    label: `Koš`,
    phase: [`progress`],
    button: { icon: `futbol`, text: undefined, size: undefined },
    scoreTypes: {
      long: `střela z dálky (nad 5 m)`,
      medium: `střela ze střední vzdálenosti (do 5 m)`,
      short: `střela z blízka (pod košem)`,
      running_in: `donáška`,
      free_pass: `volný hod`,
      penalty: `penalta`,
    },
    fields: [
      `team`,
      `localTime`,
      { name: `who`, control: `whoAttack`, label: `Střelec / Střelkyně` },
      `scoreType`,
      `note`,
    ],
    required: [`team`, `who`], // localTime is always mandatory
    message: (event, def, getTeamTitle, getPersonTitle) => (
      <>
        <span>
          {event.who || event.who === 0 ? (
            <>
              <strong>{getPersonTitle(event.who, false)}</strong> (
              {getTeamTitle(event.team)})
            </>
          ) : (
            <strong>{getTeamTitle(event.team)}</strong>
          )}
          {` `}
          střílí koš{` `}
          {event.scoreType &&
            def.scoreTypes[event.scoreType] &&
            `- ${def.scoreTypes[event.scoreType]}`}
        </span>
        {event.note && (
          <>
            <br />
            <span>{event.note}</span>
          </>
        )}
      </>
    ),
  },

  timeout: {
    label: `Time-out`,
    phase: [`progress`],
    button: { icon: undefined, text: `TO`, size: undefined },
    fields: [`team`, `localTime`, `note`],
    required: [`team`],
    message: (event, def, getTeamTitle, getPersonTitle) => (
      <>
        <span>
          {def.label} {getTeamTitle(event.team)}
        </span>
        {event.note && (
          <>
            <br />
            <span>{event.note}</span>
          </>
        )}
      </>
    ),
  },

  medic: {
    label: `Ošetření`,
    phase: [`progress`],
    button: { icon: `briefcase-medical` },
    fields: [`team`, `localTime`, `who`, `note`],
    required: [`team`, `who`],
    message: (event, def, getTeamTitle, getPersonTitle) => (
      <>
        <span>
          {def.label}
          {` `}
          {event.who || event.who === 0 ? (
            <>
              <strong>{getPersonTitle(event.who, false)}</strong> (
              {getTeamTitle(event.team)})
            </>
          ) : (
            <strong>{getTeamTitle(event.team)}</strong>
          )}
        </span>
        {event.note && (
          <>
            <br />
            <span>{event.note}</span>
          </>
        )}
      </>
    ),
  },

  substitution: {
    label: `Střídání`,
    phase: [`progress`],
    button: { icon: `exchange-alt` },
    fields: [
      `team`,
      `localTime`,
      { name: `who`, control: `whoAttackDefense`, label: `Ze hry` },
      { name: `successor`, control: `whoReserves`, label: `Do hry` },
      {
        name: `successorShirt`,
        control: `number`,
        label: `Do hry - číslo dresu`,
      },
      `note`,
    ],
    required: [`team`, `who`, `successor`, `successorShirt`],
    message: (event, def, getTeamTitle, getPersonTitle) => (
      <>
        <span>
          {def.label} {getTeamTitle(event.team)}
          {(event.who || event.who === 0) &&
            (event.successor || event.successor === 0) && (
              <>
                :{` `}
                <FA className="text-green-700" icon="caret-up" size="lg" />
                {` `}
                <strong>{getPersonTitle(event.successor, true)}</strong> střídá
                za
                {` `}
                <FA className="text-red-700" icon="caret-down" size="lg" />
                {` `}
                {getPersonTitle(event.who, false)}
              </>
            )}
        </span>
        {event.note && (
          <>
            <br />
            <span>{event.note}</span>
          </>
        )}
      </>
    ),
  },

  new_captain: {
    label: `Určení kapitána`,
    phase: [`progress`],
    button: { text: `C` },
    fields: [
      `team`,
      `localTime`,
      { name: `who`, control: `whoAttackDefense`, label: `Nový kapitán` },
      `note`,
    ],
    required: [`team`, `who`],
    message: (event, def, getTeamTitle, getPersonTitle) => (
      <>
        <span>
          {event.who || event.who === 0 ? (
            <>
              Kapitánskou pásku {getTeamTitle(event.team)} přebírá{` `}
              <strong>{getPersonTitle(event.who, false)}</strong>
            </>
          ) : (
            <>
              Změna kapitána {event.team && `týmu ${getTeamTitle(event.team)}`}
            </>
          )}
        </span>
        {event.note && (
          <>
            <br />
            <span>{event.note}</span>
          </>
        )}
      </>
    ),
  },

  new_shirt: {
    label: `Změna čísla dresu`,
    phase: [`progress`],
    button: { text: `#` },
    fields: [
      `team`,
      `localTime`,
      { name: `who`, control: `whoAttackDefense` },
      {
        name: `newShirt`,
        control: `number`,
        label: `Nové číslo dresu`,
      },
      `note`,
    ],
    required: [`team`, `who`, `newShirt`],
    message: (event, def, getTeamTitle, getPersonTitle) => (
      <>
        <span>
          {(event.who || event.who === 0) &&
          (event.newShirt || event.newShirt === 0) ? (
            <>
              <strong>{getPersonTitle(event.who, false)}</strong>(
              {getTeamTitle(event.team)}) mění číslo dresu na{` `}
              <strong>#{event.newShirt}</strong>
            </>
          ) : (
            <>
              Změna čísla dresu{` `}
              {event.team && `v týmu ${getTeamTitle(event.team)}`}
            </>
          )}
        </span>
        {event.note && (
          <>
            <br />
            <span>{event.note}</span>
          </>
        )}
      </>
    ),
  },

  card: {
    label: `Karta`,
    phase: [`progress`],
    button: { icon: `clone` },
    cardTypes: {
      yellow: `žlutá karta`,
      red: `červená karta`,
    },
    fields: [`team`, `localTime`, `who`, `cardType`, `note`],
    required: [`team`, `who`, `cardType`],
    message: (event, def, getTeamTitle, getPersonTitle) => (
      <>
        <span className="inline-block first-letter:capitalize">
          {event.cardType ? def.cardTypes[event.cardType] : `karta`} pro{` `}
          {event.who || event.who === 0 ? (
            <>
              <strong>{getPersonTitle(event.who, false)}</strong> (
              {getTeamTitle(event.team)})
            </>
          ) : (
            getTeamTitle(event.team)
          )}
        </span>
        {event.note && (
          <>
            <br />
            <span>{event.note}</span>
          </>
        )}
      </>
    ),
  },

  time: {
    label: `Zahájení/ukončení periody`,
    phase: [`progress`],
    button: { icon: `stopwatch` },
    timeTypes: {
      matchStart: `start utkání`,
      periodEnd: `konec periody`,
      periodStart: `start periody`,
      matchEnd: `konec utkání`,
      referee: `čas rozhodčího`,
    },
    fields: [`localTime`, `timeType`, `note`],
    required: [`timeType`],
    message: (event, def, getTeamTitle, getPersonTitle) => (
      <>
        <span className="inline-block first-letter:capitalize">
          <strong>
            {event.timeType
              ? def.timeTypes[event.timeType]
              : def.timeTypes[`referee`]}
          </strong>
        </span>
        {event.note && (
          <>
            <br />
            <span>{event.note}</span>
          </>
        )}
      </>
    ),
  },

  missed: {
    label: `Neproměněná šance`,
    phase: [`progress`],
    button: { icon: `times`, text: undefined, size: undefined },
    scoreTypes: {
      long: `střela z dálky (nad 5 m)`,
      medium: `střela ze střední vzdálenosti (do 5 m)`,
      short: `střela z blízka (pod košem)`,
      running_in: `donáška`,
      free_pass: `volný hod`,
      penalty: `penalta`,
    },
    fields: [
      `team`,
      `localTime`,
      { name: `who`, control: `whoAttack`, label: `Střelec / Střelkyně` },
      `scoreType`,
      `note`,
    ],
    required: [`team`, `who`],
    message: (event, def, getTeamTitle, getPersonTitle) => (
      <>
        <span>
          {event.who || event.who === 0 ? (
            <>
              <strong>{getPersonTitle(event.who, false)}</strong> (
              {getTeamTitle(event.team)})
            </>
          ) : (
            <strong>{getTeamTitle(event.team)}</strong>
          )}
          {` ${event.team ? `neproměňuje šanci` : def.label} `}
          {event.scoreType &&
            def.scoreTypes[event.scoreType] &&
            `- ${def.scoreTypes[event.scoreType]}`}
        </span>
        {event.note && (
          <>
            <br />
            <span>{event.note}</span>
          </>
        )}
      </>
    ),
  },

  note: {
    label: `Poznámka`,
    phase: [`progress`],
    button: { icon: `comments` },
    fields: [`localTime`, { name: `team`, nullable: true }, `note`],
    required: [`note`],
    message: (event, def, getTeamTitle, getPersonTitle) => (
      <span className="inline-block first-letter:capitalize">
        {event.team && <strong>{getTeamTitle(event.team)}:</strong>}
        {` `}
        {event.note}
      </span>
    ),
  },
};

export default eventsDef;

function StartingPlayersRole({ team, role, getPersonTitle, className }) {
  return (
    <div className={className}>
      <span className="underline">{getRoleLabel(role)}</span>
      <ul>
        {team[role].map((t, i) => (
          <li key={`${role}${i}`}>{getPersonTitle(t.id)}</li>
        ))}
      </ul>
    </div>
  );
}

function getRoleLabel(role) {
  switch (role) {
    case `attack`:
      return `Útok`;
    case `defense`:
      return `Obrana`;
    case `reserves`:
      return `Lavička`;
    default:
      return `...`;
  }
}
