import eventsReducer, { sameEvent } from "./match-events";
import eventDetailReducer from "./event-detail";
import { findColor } from "../utils/colors";

export function actionUpdateMatchStart(matchStartData) {
  return {
    type: matchStartData?.extra
      ? `UPDATE_MATCH_START_EXTRA`
      : `UPDATE_MATCH_START`,
    payload: matchStartData,
  };
}

export function actionFinishMatch(matchData) {
  return {
    type: `FINISH_MATCH`,
    payload: matchData,
  };
}

export function getDetailedEvent(match) {
  if (!match) return null;
  if (!match.eventDetail?.selected && match.eventDetail?.selected !== 0)
    return null;
  if (!match.events) return null;

  const uuid = match.eventDetail?.selected;
  return match.events.find((event) => sameEvent(uuid, event));
}

export default function matchReducer(match, action) {
  if (
    action.type === `SELECT_MATCH` &&
    action.payload?.serverMatch &&
    (!match?.version ||
      (action.payload.serverMatch?.version &&
        action.payload.serverMatch.version > match.version))
  )
    match = action.payload.serverMatch;
  if (action.type === `UPDATE_MATCH_START_EXTRA`)
    match = matchStartDataExtraReducer(match, action);
  else if (action.type === `FINISH_MATCH`)
    match = {
      ...match,
      state: action.payload?.state,
      finalNote: action.payload?.finalNote,
    };
  if (!match) match = {};

  return {
    ...match,
    eventDetail: eventDetailReducer(match.eventDetail, action),
    events: eventsReducer(match.events, action),
    ...(action.type === `SELECT_MATCH` && match.id === action.payload?.selected
      ? { state: match?.state ?? `start` }
      : {}),
  };
}

function matchStartDataExtraReducer(match, action) {
  const offsetMinutes = -1 * new Date().getTimezoneOffset();
  const zoneOffset = `${offsetMinutes >= 0 ? `+` : `-`}${String(
    Math.abs(offsetMinutes) / 60
  ).padStart(2, `0`)}:${String(Math.abs(offsetMinutes) % 60).padStart(2, `0`)}`;
  const {
    competition,
    home,
    guest,
    start,
    venue,
    organizer,
    referee1,
    referee2,
    state,
  } = action.payload;
  return {
    ...match,
    ...{
      state,
      competition,
      home,
      guest,
      start: new Date(
        typeof start === `string` && start.length === 16
          ? `${start}:00.000${zoneOffset}`
          : start
      ),
      venue,
      organizer,
      referee1,
      referee2,
    },
    version: (match?.version ?? 0) + 1,
    homeRoster: {
      ...match.homeRoster,
      players: Object.fromEntries(
        (action.payload.homeRosterExtra ?? []).map((p) => {
          const nameTokens = (p.name ?? `příjmení jméno`).trim().split(/\s+/);
          return [
            p.uuid,
            {
              id: p.uuid,
              firstname: nameTokens.slice(1).join(` `),
              surname: nameTokens.slice(0, 1).join(` `),
              birth: undefined,
            },
          ];
        })
      ),
    },
    guestRoster: {
      ...match.guestRoster,
      players: Object.fromEntries(
        (action.payload.guestRosterExtra ?? []).map((p) => {
          const nameTokens = (p.name ?? `příjmení jméno`).trim().split(/\s+/);
          return [
            p.uuid,
            {
              id: p.uuid,
              firstname: nameTokens.slice(1).join(` `),
              surname: nameTokens.slice(0, 1).join(` `),
              birth: undefined,
            },
          ];
        })
      ),
    },
  };
}

export function createBackgroundGetter(match) {
  const { homeColor, guestColor } = match;
  const homeBg = findColor(homeColor);
  const guestBg = findColor(guestColor);

  return function getBackground(team) {
    switch (team) {
      case `home`:
        return homeBg;
      case `guest`:
        return guestBg;
      default:
        return `bg-gray-100`;
    }
  };
}
